








import { Vue, Component } from "vue-property-decorator";
import VesselEnquiryActiveTable from "@/components/vessel/enquiry/VesselEnquiryActiveTable.vue";

@Component({ components: { VesselEnquiryActiveTable } })
export default class ExpiringEnquiriesOverview extends Vue {}
