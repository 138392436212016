











































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import nameof from "@/utility/nameof";
import { ContractType, VesselEnquiryModel } from "@/api/generated";
import { DataTableHeader } from "vuetify";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

const vesselHeaders: Array<DataTableHeader<VesselEnquiryModel>> = [
  { text: "", value: "actions", sortable: false },
  { text: "Vessel", value: nameof<VesselEnquiryModel>("vesselName") },
  { text: "Contract Start", value: nameof<VesselEnquiryModel>("startDate") },
  { text: "Contract End", value: nameof<VesselEnquiryModel>("endDate") },
  { text: "Monthly Fee", value: nameof<VesselEnquiryModel>("monthlyFeeGbp") },
  {
    text: "Crew Member Assigned",
    value: nameof<VesselEnquiryModel>("assigneeNames")
  },
  {
    text: "Job Roles Required",
    value: nameof<VesselEnquiryModel>("jobRoles")
  },
  {
    text: "Nationality",
    value: nameof<VesselEnquiryModel>("nationalityPreferenceNationality")
  }
];

const companiesHeaders: Array<DataTableHeader<VesselEnquiryModel>> = [
  { text: "", value: "actions", sortable: false },
  { text: "Company", value: nameof<VesselEnquiryModel>("vesselName") },
  { text: "Contract Start", value: nameof<VesselEnquiryModel>("startDate") },
  { text: "Contract End", value: nameof<VesselEnquiryModel>("endDate") },
  { text: "Monthly Fee", value: nameof<VesselEnquiryModel>("monthlyFeeGbp") },
  {
    text: "Persons Assigned",
    value: nameof<VesselEnquiryModel>("assigneeNames")
  },
  {
    text: "Job Roles Required",
    value: nameof<VesselEnquiryModel>("jobRoles")
  },
  {
    text: "Nationality",
    value: nameof<VesselEnquiryModel>("nationalityPreferenceNationality")
  }
];

@Component
export default class VesselEnquiryActiveTable extends Vue {
  @Prop(Boolean) isCompaniesTable?: boolean;

  private loading = false;
  private vessels: Array<VesselEnquiryModel> = [];
  private headers: Array<DataTableHeader<VesselEnquiryModel>> = this.isCompaniesTable ? companiesHeaders : vesselHeaders;

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getActiveVesselEnquiries();
  }

  private async getActiveVesselEnquiries() {
    try {
      this.loading = true;
      if (this.agencyAdminRole) {
        const response = await Api.VesselEnquiryService.apiVesselenquiryActiveUserIdDiscriminatorGet(
          userModule.userId,
          this.isCompaniesTable ? ContractType.NUMBER_1 : ContractType.NUMBER_0
        );
        this.vessels = response.data;
      } else {
        const response = await Api.VesselEnquiryService.apiVesselenquiryActiveDiscriminatorGet(this.isCompaniesTable ? ContractType.NUMBER_1 : ContractType.NUMBER_0);
        this.vessels = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch vessel enquires");
    } finally {
      this.loading = false;
    }
  }

  private onEditVesselEnquiry(model: VesselEnquiryModel) {
    if (!model.vesselEnquiryId) {
      return;
    }

    if (this.isCompaniesTable) {
      this.$router.push({
        name: "Company Enquiry Edit",
        params: { vesselEnquiryId: model.vesselEnquiryId }
      });
    } else {
      this.$router.push({
        name: "Vessel Enquiry Edit",
        params: { vesselEnquiryId: model.vesselEnquiryId }
      });
    }
  }

  private validityStyle = (item: VesselEnquiryModel) => {
    return item.valid ? "" : "error--text";
  };
}
